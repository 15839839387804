import React from 'react';

function Footer() {
  return (
    <footer>
      <p>&copy; Rasa Novum</p>
    </footer>
  );
}

export default Footer;
